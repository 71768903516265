/* istanbul ignore file */
const HTTPS = 'https://';
const DOMAIN = '.statefarm.com';
const PROOFING = 'proofing';
const valid_envs = ['env1','env2','env3','env4','env5','env6','env7','perf','prep'];

const isProd = import.meta.env.VITE_TEST_ENV === 'prod' || import.meta.env.VITE_TEST_ENV === '' || import.meta.env.VITE_TEST_ENV === 'production';

// Squelch console.* statements from prod; redefine them to anons that return empty.
if (isProd) {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.group = () => {};
    console.groupEnd = () => {};
}

const presetTestEnv = import.meta.env.VITE_TEST_ENV === 'local' ? 'env3' : import.meta.env.VITE_TEST_ENV;
let formalTestEnv = presetTestEnv;

const saveEnvFromQuery = () => {

    let environment = '';
    try {
        environment = new URL(window.location.toString()).searchParams.get('env');
    } catch (error) {
        console.warn("Invalid URL:", error.message)
    }

    if (!isProd && !!environment && valid_envs.includes(environment) ) {
        sessionStorage.setItem('env', environment);
    };
} 

const setupFormalTestEnv = () => {

    const envFromSession = sessionStorage.getItem('env');

    if (!isProd && !!envFromSession && valid_envs.includes(envFromSession)) {
        formalTestEnv = envFromSession;
        console.log('setting formalTestEnv to: ' + formalTestEnv);
    } else { // reset to default
        formalTestEnv = presetTestEnv;
        sessionStorage.removeItem('env');
    }
}

// TODO: I don't know if the MT-UI will ever be called by MA at its .c1.statefarm domain or not, but I feel we should go .com throughout here, as it's all public-facing, no?
const buildAPIURL = () => {
    if (isProd) {
        return 'https://my-tracker-api.myaccnts.c1.statefarm';
    } else if (import.meta.env.VITE_TEST_ENV === 'local') {
        return 'http://127.0.0.1:8080';
    }
    return 'https://my-tracker-api.myaccnts.test.ic1.statefarm';
};

const buildDocumentCenterAutoFilteredURL = () => {
    let subDomain = 'edocuments';

    if (isProd) {
        return `${HTTPS}${subDomain}${DOMAIN}/DocumentCenterUI/?businessFilterTitle=Auto`;
    }

    subDomain = `${subDomain}-${formalTestEnv}.test`;
    return `${HTTPS}${subDomain}${DOMAIN}/DocumentCenterUI/?businessFilterTitle=Auto`;
};

const buildDPPURL = () => {
    let subDomain = 'financials';

    if (isProd) {
        return `${HTTPS}${subDomain}${DOMAIN}/digital-pay/billHistory`;
    }

    subDomain = `${subDomain}-${formalTestEnv}.test`;
    return `${HTTPS}${subDomain}${DOMAIN}/digital-pay/billHistory`;
};

const buildMakeAPaymentURL = () => {
    let subDomain = 'financials';

    if (isProd) {
        return `${HTTPS}${subDomain}${DOMAIN}/digital-pay/paymentCenter?cmpid=tracker_DPP`;
    }

    subDomain = `${subDomain}-${formalTestEnv}.test`;
    return `${HTTPS}${subDomain}${DOMAIN}/digital-pay/paymentCenter?cmpid=tracker_DPP`;
};

const paymentScheduleURL = () => {
    if (isProd) {
        return 'https://financials.statefarm.com/digital-pay/paymentCenter';
    } else {
        return `https://financials-${formalTestEnv}.test.statefarm.com/digital-pay/paymentCenter`;
    }

    // TODO: When DPP is done fixing the /paymentSchedule endpoint, we can use the code below once again so just commenting it out for now.
    // let subDomain = 'financials';
    // let contextRoot = '/digital-pay/paymentSchedule';
    // if (isProd) {
    //     return `${HTTPS}${subDomain}${DOMAIN}${contextRoot}`;
    // }

    // subDomain = `${subDomain}-${formalTestEnv}.test`;
    // return `${HTTPS}${subDomain}${DOMAIN}${contextRoot}`;
};

const buildMyAccountsURL = () => {
    let subDomain = 'apps';
    const apiContextRoot = '/my-accounts/';

    if (isProd) {
        return `${HTTPS}${subDomain}${DOMAIN}${apiContextRoot}`;
    }

    subDomain = `apps-${formalTestEnv}.test`;
    return `${HTTPS}${subDomain}${DOMAIN}${apiContextRoot}`;
};

const buildLogoutURL = () => {
    return isProd ? `${HTTPS}${PROOFING}${DOMAIN}/login-ui/logout` : `${HTTPS}${PROOFING}-${formalTestEnv}.test${DOMAIN}/login-ui/logout`;
};

const buildLoginURL = () => {
    return isProd ? `${HTTPS}${PROOFING}${DOMAIN}/login-ui/login` : `${HTTPS}${PROOFING}-${formalTestEnv}.test${DOMAIN}/login-ui/login`;
};

// TODO: Externalize the Okta Issuer URL into an AWS config or AWS SSM
const buildOktaIssuerURL = () => {
    return isProd ? 'https://auth.statefarm.com/oauth2/aus2clrnwwWY9kBbc4h7' : 'https://auth.test.statefarm.com/oauth2/aus3w6pgj4rZqVJTT1d7'
}

export const setupConfig = () => {

    saveEnvFromQuery();
    setupFormalTestEnv();

    return {
        API_URL: buildAPIURL(),
        API_TIMEOUT_IN_MS: 14000,
        LOGOUT_URL: buildLogoutURL(),
        LOGIN_URL: buildLoginURL(),
        MY_ACCOUNTS_URL: buildMyAccountsURL(),
        OKTA_ISSUER_URL: buildOktaIssuerURL(),
        DOCUMENT_CENTER_AUTO_URL: buildDocumentCenterAutoFilteredURL(),
        DPP_URL: buildDPPURL(),
        MAKE_PAYMENT_URL: buildMakeAPaymentURL(),
        PAYMENT_SCHEDULE_URL: paymentScheduleURL(),
        TEST_ENV: formalTestEnv,
        IS_PRODUCTION_ENV: isProd
    };
};

export default setupConfig;
